import { FunctionComponent, useEffect, useRef } from 'react';
import { useAuthentication } from '@/hooks/useAuthentication';
import { getTimeInSeconds } from '@/utils/time';
import { usePostRefreshMutation } from '@/api/authentication';
import { useSlice } from '@/hooks/useSlice';
import { authSlice } from '@/slices/authSlice';

export const AuthContainer: FunctionComponent<
    React.PropsWithChildren<unknown>
> = ({ children }) => {
    const { expiresIn, token, logout } = useAuthentication();
    const {
        state: { refreshCode },
    } = useSlice(authSlice, 'auth');
    const [postRefresh] = usePostRefreshMutation();
    const firstRenderDone = useRef(false);

    useEffect(() => {
        firstRenderDone.current = true;
        if (token && refreshCode) {
            if (expiresIn < getTimeInSeconds()) {
                logout();
            } else {
                const timeRemaining = expiresIn - getTimeInSeconds();
                // subtract 5 seconds as a buffer for refresh
                window.setTimeout(() => {
                    if (refreshCode) {
                        postRefresh({ refreshCode })
                            .unwrap()
                            .catch(() => {
                                logout();
                            });
                    }
                }, timeRemaining * 1000 - 5000);
            }
        } else {
            logout();
        }
    }, [expiresIn, refreshCode, token]);

    return firstRenderDone ? <>{children}</> : null;
};
