import { FunctionComponent, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import { useGetCheckoutTicketCollections } from '@/api/checkout';
import { CheckoutTicketCollectionCard } from '@/components/CollectionCard/CheckoutTicketCollectionCard/CheckoutTicketCollectionCard';
import { motion, AnimatePresence } from 'framer-motion';
import { InsuranceComponentSjReact } from '@crossnative/insurance-component/react';
import { useTranslation } from '@/hooks/useTranslation';
import { IS_DEVELOPMENT } from '@/constants';
import { usePageConfig } from '@/hooks/useConfig';

export const CartOverviewContainer: FunctionComponent<
    PropsWithChildren
> = () => {
    const ticketCollections = useGetCheckoutTicketCollections();
    const { isOeamtc, isPortal } = usePageConfig();
    const { locale } = useTranslation();
    const isInsuranceEnabled = !isOeamtc && isPortal;

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                overflow: 'scroll',
            }}
        >
            {isInsuranceEnabled && (
                <InsuranceComponentSjReact
                    stage={IS_DEVELOPMENT ? 'test' : undefined}
                    language={locale.toLocaleUpperCase()}
                />
            )}
            <AnimatePresence>
                {ticketCollections.reverse().map((ticketCollection) => (
                    <motion.div
                        key={ticketCollection.id}
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{
                            ease: [0.175, 0.885, 0.32, 1.275],
                        }}
                    >
                        <Box
                            sx={{
                                mb: 3,
                            }}
                        >
                            <CheckoutTicketCollectionCard
                                ticketCollection={ticketCollection}
                            />
                        </Box>
                    </motion.div>
                ))}
            </AnimatePresence>
        </Box>
    );
};
