import { Store } from '@/store';
import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { isOfTypeRTKAPIError } from '@/models/request';
import { authSlice } from '@/slices/authSlice';
import { cartSlice } from '@/slices/cartSlice';

const rdxBaseQuery = fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders: (headers, { getState }) => {
        const { config } = getState() as Store;
        const { pageConfig, isOeamtc, locale } = config;

        if (pageConfig && !isOeamtc) {
            const { url, configuration } = pageConfig;
            headers.set('X-IntegrationToken', configuration.integrationToken);
            headers.set('X-IntegrationUrl', url);
        }

        headers.set('Content-Type', 'application/json');
        headers.set('X-Starjack-Language', locale);

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const logout = () => {
        // handle token expired
        api.dispatch(authSlice.actions.clearAuthData());
        api.dispatch(cartSlice.actions.clearCart());
    };

    const result = await rdxBaseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        if (isOfTypeRTKAPIError(result.error)) {
            const { data } = result.error;
            const { errors } = data;

            if (errors && errors.length > 0) {
                const tokenExpiredError = errors.find(
                    (e) => e.error === 'token_expired'
                );

                if (tokenExpiredError) {
                    logout();
                }
            }
        }
    }
    return result;
};

export const baseApiAuthenticated = createApi({
    reducerPath: 'baseApiAuthenticated',
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
});

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: fetchBaseQuery({}),
    endpoints: () => ({}),
});
