import { ChangeEvent, FunctionComponent } from 'react';
import { FlexContainer } from '@/components/Layout/Container';
import {
    CheckoutTicket,
    getTicketPriceWithInsurance,
} from '@/models/checkout/checkoutTicket';
import {
    Box,
    FormControlLabel,
    Skeleton,
    Switch,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { getPropByCurrentLocale } from '@/utils/locale';
import { useTranslation } from '@/hooks/useTranslation';
import { Button } from '@/components/Layout/Button/Button';
import { getMoneyAsString } from '@/utils/general';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { formatDate } from '@/utils/time';
import { IdentificationType } from '@/models/cart';
import {
    useDeleteCheckoutTicketMutation,
    useGetCurrentCheckoutSession,
    usePutCheckoutTicketMutation,
} from '@/api/checkout';
import { ADD_INSURANCE_CACHE_KEY } from '@/components/CollectionCard/CheckoutTicketCollectionCard/InsuranceAddContainer';
import { PromotionButtons } from '@/components/CollectionCard/CheckoutTicketCollectionCard/PromotionButtons';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { usePageConfig } from '@/hooks/useConfig';

type PersonAssignmentSingleTicketProps = {
    rowIndex: number;
    ticket: CheckoutTicket;
    onPersonAssignClick: (personId: string) => void;
};

export const PersonAssignmentSingleTicket: FunctionComponent<
    PersonAssignmentSingleTicketProps
> = ({ rowIndex, ticket, onPersonAssignClick }) => {
    if (!ticket.persons.length) {
        throw new Error('Ticket must have at least one person');
    }

    const { getTranslated } = useTranslation();
    const { data: checkoutSession } = useGetCurrentCheckoutSession();
    const [putCheckoutTicket, { isLoading: singleTicketLoading }] =
        usePutCheckoutTicketMutation();
    const [, { isLoading: addInsuranceLoading }] = usePutCheckoutTicketMutation(
        { fixedCacheKey: ADD_INSURANCE_CACHE_KEY }
    );
    const [deleteTicket, { isLoading: deleteTicketLoading }] =
        useDeleteCheckoutTicketMutation();
    const { isOeamtc, isPortal } = usePageConfig();
    const downMd = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const hasInsurance =
        !isOeamtc && isPortal && ticket.aonInsurances?.length > 0;
    const insuranceSelected = !!ticket.aonInsurances?.[0]?.selected;
    const personSelected = !!ticket.persons[0]?.assignedIdentification;

    const loading =
        singleTicketLoading || addInsuranceLoading || deleteTicketLoading;

    const onToggleInsurance = (event: ChangeEvent<HTMLInputElement>) => {
        putCheckoutTicket({
            checkoutId: checkoutSession.id,
            id: ticket.id,
            identificationType: ticket.identificationType,
            tariffIdentifier: event.target.checked
                ? ticket.aonInsurances[0].tariffIdentifier
                : null,
        });
    };

    const getProductionTypeString = () => {
        const { type, serialNumber } = ticket.persons[0].assignedIdentification;
        if (
            (type === IdentificationType.KEYCARD ||
                type === IdentificationType.INTERNAL_PRODUCTION) &&
            !!serialNumber
        ) {
            return `${getTranslated(type)}, SN: ${serialNumber.slice(
                0,
                5
            )} ... ${serialNumber.slice(-5)}`;
        }

        return getTranslated(type);
    };

    const onDeleteTicket = (ticketId: string) => {
        deleteTicket({
            checkoutId: checkoutSession.id,
            ticketId: ticketId,
        });
    };

    return (
        <FlexContainer
            sx={(theme) => ({
                p: 3,
                alignItems: 'center',
                width: '100%',
                position: 'relative',

                [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                },
            })}
        >
            <Skeleton
                sx={{
                    ...(loading && {
                        position: 'absolute',
                        left: 0,
                        rigth: 0,
                        bottom: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.85)',
                        zIndex: 1,
                        display: 'flex',
                        transformOrigin: 'unset',
                        transform: 'unset',
                    }),

                    ...(!loading && {
                        display: 'none',
                    }),
                }}
            />
            <FlexContainer
                sx={(theme) => ({
                    gap: 2,
                    width: 252,

                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                    },
                })}
                column
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        textTransform: 'capitalize',
                    }}
                >
                    {rowIndex}. {getTranslated('core.person')}
                </Typography>

                <Box
                    sx={{
                        ml: 2,
                    }}
                >
                    {personSelected ? (
                        <FlexContainer
                            sx={{
                                alignItems: 'center',
                            }}
                        >
                            <TaskAltIcon
                                color='success'
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                    mr: 2,
                                }}
                            />

                            <Box>
                                <Typography>
                                    {
                                        ticket.persons[0].assignedIdentification
                                            .firstname
                                    }
                                    ,{' '}
                                    {
                                        ticket.persons[0].assignedIdentification
                                            .lastname
                                    }{' '}
                                    (
                                    {formatDate(
                                        ticket.persons[0].assignedIdentification
                                            .birthday
                                    )}
                                    )
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '0.8rem',
                                    }}
                                >
                                    {getProductionTypeString()}
                                </Typography>
                            </Box>
                        </FlexContainer>
                    ) : (
                        <Typography>
                            {getPropByCurrentLocale(ticket.persons[0].name)}
                        </Typography>
                    )}

                    {hasInsurance && (
                        <FormControlLabel
                            sx={(theme) => ({
                                [theme.breakpoints.down('md')]: {
                                    mt: 2,
                                },
                            })}
                            control={
                                <Switch
                                    color='success'
                                    checked={insuranceSelected}
                                    onChange={onToggleInsurance}
                                />
                            }
                            label={`${getPropByCurrentLocale(
                                ticket.aonInsurances[0].tariffName
                            )} ${getTranslated(
                                'checkoutTicketCollection.insuranceSwitch.insurance'
                            )}`}
                        />
                    )}
                </Box>
            </FlexContainer>

            <FlexContainer
                sx={(theme) => ({
                    alignItems: 'center',
                    flexGrow: 1,

                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                    },
                })}
            >
                {!downMd && <PromotionButtons checkoutTicket={ticket} />}

                <FlexContainer
                    sx={(theme) => ({
                        ml: 'auto',
                        alignItems: 'center',
                        width: 168,

                        [theme.breakpoints.down('md')]: {
                            mt: 3,
                            width: '100%',
                        },
                    })}
                    column
                >
                    <Typography
                        sx={(theme) => ({
                            fontSize: '1.05rem',
                            fontWeight: 500,
                            mb: insuranceSelected ? 0 : 2,

                            [theme.breakpoints.down('md')]: {
                                fontWeight: 600,
                            },
                        })}
                    >
                        {getMoneyAsString(getTicketPriceWithInsurance(ticket))}
                    </Typography>

                    {insuranceSelected && (
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                mb: 2,
                            }}
                        >
                            {getTranslated(
                                'insurance.pricePerPersonPrice.label',
                                {
                                    tariffPrice: getMoneyAsString(
                                        ticket.aonInsurances[0].price
                                    ),
                                }
                            )}
                        </Typography>
                    )}

                    <FlexContainer
                        sx={{
                            width: '100%',
                        }}
                        column
                        center
                    >
                        {downMd && (
                            <FlexContainer
                                sx={{
                                    mb: 2,
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                <PromotionButtons checkoutTicket={ticket} />
                            </FlexContainer>
                        )}

                        <Button
                            sx={(theme) => ({
                                [theme.breakpoints.down('md')]: {
                                    width: '100%',
                                    maxWidth: 360,

                                    mb: 3,
                                },
                            })}
                            onClick={() => {
                                onPersonAssignClick(ticket.persons[0].id);
                            }}
                            variant='outlined'
                        >
                            {getTranslated(
                                ticket.persons[0].assignedIdentification
                                    ? 'Core.change'
                                    : 'CheckoutPage.button.choosePerson'
                            )}
                        </Button>

                        {downMd && (
                            <Button
                                onClick={() => {
                                    onDeleteTicket(ticket.id);
                                }}
                                variant='outlined'
                                color='error'
                            >
                                {getTranslated(
                                    'CheckoutPage.button.deleteTicket'
                                )}
                            </Button>
                        )}
                    </FlexContainer>
                </FlexContainer>

                {!downMd && (
                    <IconButton
                        onClick={() => {
                            onDeleteTicket(ticket.id);
                        }}
                        sx={{
                            mx: 2,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            </FlexContainer>
        </FlexContainer>
    );
};
