import { authSlice } from '@/slices/authSlice';
import { urlBuilder } from '@/utils/api';
import jwt_decode from 'jwt-decode';
import {
    ActivationData,
    convertLoginInformationToFrontend,
    LoginInformation,
    OAuthCreateUserData,
    RegistrationData,
} from '@/models/user';
import { IS_MOBILE_APP } from '@/constants';
import { Store } from '@/store';

import { BackendType } from '@/models/general';
import { baseApi, baseApiAuthenticated } from '@/api/index';

type RecoveryParams = {
    recoveryCode: string;
    password: string;
    password2: string;
};

type PostLoginResponse = {
    jwt: string;
    refreshCode: string;
    longLivedToken: string;
    validFor: number;
};

type PostOAuthLoginParams = {
    providerType: string;
    redirectUrl: string;
    linkAccount?: boolean;
};

export type PostOAuthAuthResponse = {
    login: PostLoginResponse;
    linkAccount: {
        accessToken: string;
        providerType: string;
        accountName: string;
    };
    mergeAccount: {
        accessToken: string;
        providerType: string;
        accountName: string;
    };
    createAccount: {
        accessToken: string;
        providerType: string;
        userData: OAuthCreateUserData;
    };
};

const authUrls = () => ({
    login: urlBuilder('auth', '/login'),
    refresh: urlBuilder('auth', 'refresh'),
    oAuthLogin: urlBuilder('auth', 'oauth/login'),
    activation: urlBuilder('auth', 'customers/activation'),
    recovery: urlBuilder('auth', 'customers/recovery'),
    logins: urlBuilder('auth', 'customers/me/logins'),
    oAuthAuthenticate: urlBuilder('auth', 'oauth/authenticate'),
    oAuthMergeAccount: urlBuilder('auth', 'oauth/merge-account'),
    oAuthCreateAccount: urlBuilder('auth', 'oauth/create-account'),
    oAuthLinkAccount: urlBuilder('auth', 'oauth/link-account'),
    customers: urlBuilder('auth', 'customers'),
});

const authApi = baseApiAuthenticated
    .enhanceEndpoints({ addTagTypes: ['LOGIN_INFORMATION'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            postLogin: builder.mutation<
                PostLoginResponse,
                { password: string; email: string }
            >({
                query: (loginData) => ({
                    url: authUrls().login,
                    method: 'POST',
                    body: loginData,
                }),
                onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
                    try {
                        // Wait for the mutation to complete
                        const { data } = await queryFulfilled;

                        const { jwt, longLivedToken, refreshCode, validFor } =
                            data;
                        const { email } = arg;

                        const decoded: {
                            sub: string;
                            iat: number;
                            g: string[];
                        } = jwt_decode(jwt);

                        dispatch(
                            authSlice.actions.setAuthData({
                                longLiveToken: longLivedToken,
                                token: jwt,
                                refreshCode,
                                validFor,
                                uuid: decoded.sub,
                                email,
                            })
                        );
                    } catch (err) {
                        console.error('Error in post mutation:', err);
                    }
                },
            }),

            postRefresh: builder.mutation<
                PostLoginResponse,
                { refreshCode: string }
            >({
                query: (loginData) => ({
                    url: authUrls().refresh,
                    method: 'POST',
                    body: {
                        refreshCode: loginData.refreshCode,
                        rememberMe: IS_MOBILE_APP,
                    },
                }),
                onQueryStarted: async (
                    _,
                    { queryFulfilled, dispatch, getState }
                ) => {
                    try {
                        // Wait for the mutation to complete
                        const { data } = await queryFulfilled;

                        const { jwt, longLivedToken, refreshCode, validFor } =
                            data;

                        const decoded: {
                            sub: string;
                            iat: number;
                            g: string[];
                        } = jwt_decode(jwt);

                        dispatch(
                            authSlice.actions.setAuthData({
                                longLiveToken: longLivedToken,
                                token: jwt,
                                refreshCode,
                                validFor,
                                uuid: decoded.sub,

                                // this cast has to be done according to:https://github.com/reduxjs/redux-toolkit/discussions/3522
                                email: (getState() as unknown as Store).auth
                                    .email,
                            })
                        );
                    } catch (err) {
                        console.error('Error in post mutation:', err);
                    }
                },
            }),

            postDeleteLogin: builder.mutation<void, { loginId: string }>({
                query: ({ loginId }) => ({
                    url: `${authUrls().logins}/${loginId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['LOGIN_INFORMATION'],
            }),

            postOAuthLoginWithToken: builder.mutation<
                { url: string },
                PostOAuthLoginParams
            >({
                query: (params) => {
                    return {
                        url: authUrls().oAuthLogin,

                        method: 'POST',
                        body: params,
                    };
                },
            }),

            postOauthAuthentication: builder.mutation<
                PostOAuthAuthResponse,
                { code: string; state: string; rememberMe?: boolean }
            >({
                query: (params) => ({
                    url: authUrls().oAuthAuthenticate,
                    method: 'POST',
                    body: { ...params, rememberMe: !!params.rememberMe },
                }),
            }),

            postOauthMergeAccount: builder.mutation<
                PostLoginResponse,
                { state: string; accessToken: string }
            >({
                query: (params) => ({
                    url: authUrls().oAuthMergeAccount,
                    method: 'POST',
                    body: params,
                }),

                onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
                    try {
                        // Wait for the mutation to complete
                        const { data } = await queryFulfilled;

                        const { jwt, longLivedToken, refreshCode, validFor } =
                            data;

                        const decoded: {
                            sub: string;
                            iat: number;
                            g: string[];
                        } = jwt_decode(jwt);

                        dispatch(
                            authSlice.actions.setAuthData({
                                longLiveToken: longLivedToken,
                                token: jwt,
                                refreshCode,
                                validFor,
                                uuid: decoded.sub,
                                email: '',
                            })
                        );
                    } catch (err) {
                        console.error('Error in post mutation:', err);
                    }
                },
            }),

            postOAuthCreateAccount: builder.mutation<
                PostLoginResponse,
                OAuthCreateUserData & { state: string; accessToken: string }
            >({
                query: (params) => ({
                    url: authUrls().oAuthCreateAccount,
                    method: 'POST',
                    body: params,
                }),

                onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
                    try {
                        // Wait for the mutation to complete
                        const { data } = await queryFulfilled;

                        const { jwt, longLivedToken, refreshCode, validFor } =
                            data;

                        const decoded: {
                            sub: string;
                            iat: number;
                            g: string[];
                        } = jwt_decode(jwt);

                        dispatch(
                            authSlice.actions.setAuthData({
                                longLiveToken: longLivedToken,
                                token: jwt,
                                refreshCode,
                                validFor,
                                uuid: decoded.sub,
                                email: '',
                            })
                        );
                    } catch (err) {
                        console.error('Error in post mutation:', err);
                    }
                },
            }),

            postOAuthLinkAccount: builder.mutation<
                void,
                { state: string; accessToken: string }
            >({
                query: (params) => ({
                    url: authUrls().oAuthCreateAccount,
                    method: 'POST',
                    body: params,
                }),
            }),

            postCustomers: builder.mutation<void, RegistrationData>({
                query: (data) => ({
                    url: authUrls().customers,
                    method: 'POST',
                    body: data,
                }),
            }),

            postActivation: builder.mutation<PostLoginResponse, ActivationData>(
                {
                    query: (data) => ({
                        url: authUrls().activation,
                        method: 'POST',
                        body: data,
                    }),
                }
            ),

            postRecovery: builder.mutation<void, { email: string }>({
                query: (data) => ({
                    url: authUrls().recovery,
                    method: 'POST',
                    body: data,
                }),
            }),

            postRecoveryCode: builder.mutation<
                PostLoginResponse,
                RecoveryParams
            >({
                query: (data) => ({
                    url: `${authUrls().recovery}/${data.recoveryCode}`,
                    method: 'POST',
                    body: data,
                }),
            }),

            getLogins: builder.query<LoginInformation[], void>({
                query: () => authUrls().logins,
                transformResponse: (response: {
                    logins: LoginInformation<BackendType>[];
                }) => response.logins.map(convertLoginInformationToFrontend),
                providesTags: ['LOGIN_INFORMATION'],
            }),

            postDeleteAccount: builder.mutation<
                void,
                { accountId: string; password?: string }
            >({
                query: (data) => {
                    const body = {
                        id: data.accountId,
                    };

                    if (data.password) {
                        body['password'] = data.password;
                    }

                    return {
                        url: `${authUrls().customers}/me`,
                        method: 'DELETE',
                        body: body,
                    };
                },
            }),
        }),
    });

export const {
    usePostLoginMutation,
    usePostRefreshMutation,
    usePostActivationMutation,
    usePostRecoveryCodeMutation,
    usePostDeleteAccountMutation,
    usePostOauthMergeAccountMutation,
    usePostOAuthCreateAccountMutation,
    usePostOAuthLoginWithTokenMutation,
    useGetLoginsQuery,
    usePostDeleteLoginMutation,
    usePostOauthAuthenticationMutation,
    usePostOAuthLinkAccountMutation,
    usePostRecoveryMutation,
    usePostCustomersMutation,
} = authApi;

const authApiWithoutToken = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        postOAuthLoginWithoutToken: builder.mutation<
            { url: string },
            PostOAuthLoginParams
        >({
            query: (params) => {
                return {
                    url: authUrls().oAuthLogin,

                    method: 'POST',
                    body: params,
                };
            },
        }),
    }),
});

export const { usePostOAuthLoginWithoutTokenMutation } = authApiWithoutToken;
