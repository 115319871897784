import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    deleteFromLocalStorage,
    getFromLocalStorage,
    saveToLocalStorage,
} from '@/utils/storage';
import { LOCAL_STORAGE_KEY_AUTH } from '@/constants';

import { getTimeInSeconds } from '@/utils/time';

export type AuthState = {
    token: string;
    validFor: number;
    refreshCode: string;
    expiresIn: number;
    email: string;
    longLiveToken: string;
    userData: {
        uuid: string;
    };
};

const getLocalStorageState = () =>
    getFromLocalStorage(LOCAL_STORAGE_KEY_AUTH, {
        token: '',
        refreshCode: '',
        validFor: 0,
        expiresIn: 0,
        email: '',
        longLiveToken: '',
        userData: null,
    });

const initialState = getLocalStorageState();

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setAuthData(
            _,
            action: PayloadAction<{
                token: string;
                validFor: number;
                refreshCode: string;
                uuid: string;
                email: string;
                longLiveToken: string;
            }>
        ) {
            const nextState: AuthState = {
                ...action.payload,
                expiresIn: getTimeInSeconds() + action.payload.validFor,
                userData: {
                    uuid: action.payload.uuid,
                },
            };

            saveToLocalStorage(LOCAL_STORAGE_KEY_AUTH, nextState);

            return nextState;
        },

        clearAuthData() {
            deleteFromLocalStorage(LOCAL_STORAGE_KEY_AUTH);

            return {
                longLiveToken: '',
                token: '',
                refreshCode: '',
                validFor: 0,
                expiresIn: 0,
                userData: null,
                email: '',
                loginInformation: [],
            };
        },
    },
});
